import IAction from "./IAction";
import Dispatcher from "../Dispatcher/Dispatcher";

import { IProduct } from "../Stores/Catalog";

export class AddProductAction implements IAction {
    product: IProduct;

    constructor(product: IProduct) {
        this.product = product;
    }
}

export class RemoveProductAction implements IAction {
    product: IProduct;

    constructor(product: IProduct) {
        this.product = product;
    }
}

class BasketActions {
    addProduct(product: IProduct): void {
        Dispatcher.dispatch(new AddProductAction(product));
    }
    removeProduct(product: IProduct): void {
        Dispatcher.dispatch(new RemoveProductAction(product));
    }
}

export default new BasketActions();