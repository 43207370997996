import IAction from "./IAction";
import Dispatcher from "../Dispatcher/Dispatcher";

export class AppLoadedAction implements IAction { }

class AppActions {
    loadApp(): void {
        Dispatcher.dispatch(new AppLoadedAction());
    }
}

export default new AppActions();