

import * as React from "react";
import * as Catalog from "../../Stores/Catalog";
import CatalogStore from "../../Stores/CatalogStore";

import CategoryItem from "./CategoryItem";

interface ICategorySelectState {
   categories: Catalog.ICategory[];
}

export default class CategorySelect extends React.Component<{}, ICategorySelectState> {
    constructor(props: {}) {
        super(props);
        this.state = this.getStateFromStores();
    }

    render(): React.ReactElement<{}> {
        return  <div className="ui three column grid">
                    {this.state.categories.map((category: Catalog.ICategory) => {
                        return (
                            <div className="column" key={category.id}>
                                <CategoryItem category={category} />
                            </div>);
                    })}
                </div>;
    }

    componentDidMount(): void {
        CatalogStore.addListener(this.onChange);
    }

    componentWillUnmount(): void {
        CatalogStore.removeListener(this.onChange);
    }

    private onChange: () => void = () => {
        this.setState(this.getStateFromStores());
    };

    private getStateFromStores(): ICategorySelectState {
        return {
            categories: CatalogStore.getCategories()
        };
    }
}
