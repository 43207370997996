

import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ICategory, IProduct } from "../../Stores/Catalog";
import CatalogStore from "../../Stores/CatalogStore";

import ProductList from "../ProductList/ProductList";

interface IProductSelectState {
   category: ICategory;
   products: Array<IProduct>;
}

interface ProductSelectRouterProps {
    category: string
}

interface IProductSelectProps extends RouteComponentProps<ProductSelectRouterProps> {}

export default class ProductSelect extends React.Component<IProductSelectProps, IProductSelectState> {
    constructor(props: IProductSelectProps) {
        super(props);
        this.state = this.getStateFromStores();
    }

    render(): React.ReactElement<{}> {
        return  <div>
                    <ProductList products={this.state.products} />
                </div>;
    }

    componentDidMount(): void {
        CatalogStore.addListener(this.onChange);
    }

    componentWillUnmount(): void {
        CatalogStore.removeListener(this.onChange);
    }

    private onChange: () => void = () => {
        this.setState(this.getStateFromStores());
    };

    private getStateFromStores(): IProductSelectState {
        var category: string = this.props.match.params.category;
        return {
            category: CatalogStore.getCategory(category),
            products: CatalogStore.getProducts(category)
        };
    }
}
