import BaseStore from "./BaseStore";
import IAction from "./../Actions/IAction";
import Dispatcher from "../Dispatcher/Dispatcher";
import { AppLoadedAction } from "../Actions/AppActions";

import { IProduct, ICategory } from "./Catalog";

import ProductData from "../Data/products.json";
import CategoryData from "../Data/categories.json";
import { object } from "prop-types";

class CatalogStore extends BaseStore {
    private products: Array<IProduct> = new Array<IProduct>();
    private categories: Array<ICategory> = new Array<ICategory>();

    constructor() {
        super();
        Dispatcher.register((action: IAction) => this.processActions(action));
    }

    getProducts(category: string): Array<IProduct> {
        return this.products.filter((product: IProduct) => product.category === category);
    }

    getCategory(id: string): ICategory {
        for (var i: number = 0; i < this.categories.length; i++) {
            var category: ICategory = this.categories[i];
            if (category.id === id) {
                return category;
            }
        }
        return null;
    }

    getCategories(): ICategory[] {
        return this.categories;
    }

    private processActions(action: IAction): void {
        if (action instanceof AppLoadedAction) {
            this.loadProducts();
            this.loadCategories();
            this.emitChange();
        }
    };

    private loadProducts(): void {
        this.products = Object.keys(ProductData).map((key: string) => {
            var product: any = (<{[x:string]:any}>ProductData)[key];
            return <IProduct> {
                id: key,
                name: product.name,
                description: product.description,
                category: product.category,
                material: product.material,
                points: {
                    price: product.points.price,
                    nature: product.points.nature,
                    fun: product.points.fun
                }
            };
        });
    }

    private loadCategories(): void {
        this.categories = Object.keys(CategoryData).map((key: string) => {
            var category: any = (<{[x:string]:any}>CategoryData)[key]
            return <ICategory> { id: key, name: category.name };
        });
    }
}

export default new CatalogStore();