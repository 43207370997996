import * as React from "react";
import { RouteProps } from "react-router-dom";

interface ICatalogPageState {
    category: string;
}

export default class CatalogPage extends React.Component<RouteProps, ICatalogPageState> {
    render(): React.ReactElement<{}> {
        return  <div>
                    {this.props.children}
                </div>;
    }
}
