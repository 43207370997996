

import * as React from "react";
import { RouteProps } from "react-router-dom";
import { ICategory } from "../../Stores/Catalog";
import CatalogStore from "../../Stores/CatalogStore";
import BasketStore from "../../Stores/BasketStore";

interface IPartyPageState {
    budget: number;
    requiredFun: number;
    totalFun: number;
    totalNature: number;
    totalMoneySpent: number;
    partyTime: boolean;
    showResultScreen: boolean;
    showResultParty: boolean;
    categories: ICategory[];
}

export default class PartyPage extends React.Component<RouteProps, IPartyPageState> {
    constructor(props: any) {
        super(props);
        this.state = this.getStateFromStores();
    }

    render(): React.ReactElement<{}> {
        return <div>
            <h1>Feestje</h1>
            <p>Is alles aanwezig om het feest te starten?</p>
            <p>Selecteer van alle 3 de onderdelen minimaal 6 stuks!</p>
            <div className="ui grid">
                {this.state.categories.map((category: ICategory) => {
                    return <div className="row" key={category.id}>
                        <div className="two wide column">{category.name}</div>
                        <div className="twelve wide column">
                            <div className={this.getProgressClass(BasketStore.getTotalProductsForCategory(category.id))} data-percent={this.calculateProducts(BasketStore.getTotalProductsForCategory(category.id)) }>
                                <div className="bar"></div>
                                <div className="label">{this.renderMessage(category) }</div>
                            </div>
                        </div>
                        <div className="two wide column">
                            {BasketStore.getTotalProductsForCategory(category.id)}/6
                        </div>
                    </div>;
                }) }
                <div className="row">
                    <div className="two wide column"></div>
                    <div className="twelve wide column">
                        <div className="ui four cards">
                            <div className="card">
                                <div className="image">
                                    <img src="images/money.png" />
                                </div>
                                <div className="content">
                                    <div className="header">{this.state.totalMoneySpent} Euro</div>
                                    <div className="description">
                                        Je mag maximaal {this.state.budget} Euro uitgeven
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="image">
                                    <img src="images/cool.png" />
                                </div>
                                <div className="content">
                                    <div className="header">{this.state.totalFun} coolpunten</div>
                                    <div className="description">
                                        Je moet minimaal {this.state.requiredFun} coolpunten halen
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="image">
                                    <img src="images/nature.png" />
                                </div>
                                <div className="content">
                                    <div className="header">{this.state.totalNature} natuurpunten</div>
                                    <div className="description">
                                        Geef zo min mogelijk natuurpunten uit
                                    </div>
                                </div>
                            </div>
                            {this.getPartyTime() }

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="two wide column"></div>
                    <div className="eight wide column">
                        {this.getResultScreen() }
                    </div>
                </div>
            </div>
        </div>;
    }

    renderMessage(category: ICategory): string {
        var count: number = BasketStore.getTotalProductsForCategory(category.id);
        if (count === 6) {
            return "Je hebt genoeg";
        }
        if (count > 6) {
            return "Je hebt te veel";
        }
        return "Zoek nog iets uit";
    }

    getResultScreen(): React.ReactElement<{}> {
        if (this.state.showResultScreen) {
            return (
                <div className="ui active page dimmer">
                    { this.getResultParty() }
                </div>
            );
        }
        return <div></div>;
    }

    getResultParty(): React.ReactElement<{}> {
        if (this.state.showResultParty) {
            return (<div className="ui active mini modal" style={{ marginTop: -250 }}>
                <div className="image content">
                    <img className="image" src="images/party-1.jpg" />
                    <div className="description">
                        <p style={{ fontSize: "16px" }}>Let op!<br/> Wanneer je naar het resultaat gaat, kan je niet meer terug keren naar het spel. Je krijgt daar je eindscore te zien.</p>
                    </div>
                </div>
                <div className="actions">
                    <button className="ui button" onClick={() => {
                        var state: IPartyPageState = this.state;
                        state.showResultScreen = false;
                        this.setState(state);
                    }}>Ga terug</button>
                    <button className="ui button" onClick={() => {
                        var state: IPartyPageState = this.state;
                        state.showResultParty = false;
                        this.setState(state);
                    }}>Naar het resultaat</button>
                </div>
            </div>);
        } else {
            const result = BasketStore.getResult();
            return (<div className="ui active mini modal" style={{ marginTop: -250 }}>
                <div className="header">Resultaat: {result.title}</div>
                <div className="content">
                    <img className="image" src={`images/result-${result.id}.jpg`} style={{ width: '250px', height: '250px', float: 'left', marginRight: '10px', marginBottom: '10px' }} />
                    <div className="description">
                        <p style={{ fontSize: "16px" }}>{result.description}</p>
                    </div>
                </div>
            </div>);
        }
    }

    getPartyTime(): React.ReactElement<{}> {
        if (this.state.partyTime) {
            return (
                <div className="card">
                <div className="image">
                    <img src="images/feestje.png" />
                </div>
                <div className="content">
                    <div className="header">&nbsp;</div>
                    <div className="description">
                        <button className="positive ui button" onClick={this.onShowResult}>Naar het feestje</button>
                    </div>
                </div>
            </div>
            );
        }
        return <div/>;
    }

    getProgressClass(count: number): string {
        if (count > 6) {
            return "ui indicating progress error";
        }
        return "ui indicating progress";
    }

    calculateProducts(count: number): number {
        var toomany: number = count - 6;
        if (toomany > 0) {
            return 100;
        }
        return ((count / 6) * 100);
    }

    componentDidMount(): void {
        BasketStore.addListener(this.onChange);
        CatalogStore.addListener(this.onChange);
        $(".ui.progress").progress();
        $(".ui.modal").modal("show");
    }

    componentWillUnmount(): void {
        BasketStore.removeListener(this.onChange);
        CatalogStore.removeListener(this.onChange);
    }

    private onChange: () => void = () => {
        var state: IPartyPageState = this.getStateFromStores();
        console.log(state);
        this.setState(state);
    };

    private onShowResult: () => void = () => {
        var state: IPartyPageState = this.state;
        state.showResultScreen = true;
        this.setState(state);
    };

    private getStateFromStores(): IPartyPageState {
        return {
            budget: BasketStore.getBudget(),
            requiredFun: BasketStore.getRequiredFun(),
            totalFun: BasketStore.getTotalFun(),
            totalNature: BasketStore.getTotalNature(),
            totalMoneySpent: BasketStore.getTotalMoneySpent(),
            partyTime: BasketStore.isPartyTime(),
            showResultScreen: false,
            showResultParty: true,
            categories: CatalogStore.getCategories()
        };
    }
}