import BaseStore from "./BaseStore";
import IAction from "./../Actions/IAction";
import Dispatcher from "../Dispatcher/Dispatcher";
import { AddProductAction, RemoveProductAction } from "../Actions/BasketActions";

import { IGame, IResult } from "./Game";
import { IProduct } from "./Catalog";
import { AppLoadedAction } from "../Actions/AppActions";

import GameData from "../Data/game.json";
import ResultsData from "../Data/results.json";

class BasketStore extends BaseStore {
    game: IGame = null;
    results :Array<IResult> = new Array<IResult>();
    products: Array<IProduct> = new Array<IProduct>();

    constructor() {
        super();
        Dispatcher.register((action: IAction) => this.processActions(action));
    }

    addProduct(product: IProduct): void {
        if (!product.inBasket) {
            this.products.push(product);
            product.inBasket = true;
            this.emitChange();
        }
    }

    removeProduct(product: IProduct): void {
        var index: number = this.products.indexOf(product);
        if (index >= 0) {
            this.products.splice(index, 1);
            product.inBasket = false;
            this.emitChange();
        }
    }

    getProducts(): Array<IProduct> {
        return this.products;
    }

    getTotalFun(): number {
        var result: number = 0;
        this.products.forEach((product: IProduct) => { result += product.points.fun; });
        return result;
    }

    getTotalProductsForCategory(category: string): number {
        var result: number = 0;
        this.products.forEach((product: IProduct) => {
            if (product.category === category) {
                result += 1;
            }
        });
        return result;
    }

    getTotalNature(): number {
        var result: number = 0;
        this.products.forEach((product: IProduct) => { result += product.points.nature; });
        return result;
    }

    getTotalMoneySpent(): number {
        var result: number = 0;
        this.products.forEach((product: IProduct) => { result += product.points.price; });
        return result;
    }

    containsProduct(product: IProduct): boolean {
        return this.products.indexOf(product) >= 0;
    }

    getBudget(): number {
        return this.game.budget;
    }

    getRequiredFun(): number {
        return this.game.requiredFun;
    }

    isPartyTime(): boolean {
        if (this.getTotalProductsForCategory("decorations") != 6
            || this.getTotalProductsForCategory("food") != 6
            || this.getTotalProductsForCategory("presents") != 6) {
                return false;
            }
        return this.getTotalMoneySpent() <= this.getBudget() && this.getTotalFun() >= this.getRequiredFun()
    }

    getResult(): IResult {
        return this.results.find(r => eval(this.getTotalNature() + r.condition));
    }

    private loadGame(): void {
        this.game = GameData;
        this.results = Object.keys(ResultsData).map((key: string) => {
            var result: any = (<{[x:string]:any}>ResultsData)[key];
            return <IResult> {
                id: key,
                title: result.title,
                description: result.description,
                condition: result.condition
            };
        });
    }

    private processActions(action: IAction): void {
        if (action instanceof AddProductAction) {
            this.addProduct((<AddProductAction> action).product);
        } else if (action instanceof AppLoadedAction) {
            this.loadGame();
        } else if (action instanceof RemoveProductAction) {
            this.removeProduct((<RemoveProductAction> action).product);
        }
    }
}

export default new BasketStore();