import * as React from "react";
import { Link } from "react-router-dom";
import BasketStore from "../../Stores/BasketStore";

interface IMainNavigationState {
    products: number;
}

export default class MainNavigation extends React.Component<{}, IMainNavigationState> {
    constructor(props: {}) {
        super(props);
        this.state = this.getStateFromStores();
    }

    render(): React.ReactElement<{}> {
        return  <div className="ui secondary pointing menu">
                    <Link className="item" to="/">Boodschappen</Link>
                    <Link className="item" to="/party">Feestje</Link>
                    <div className="right menu">
                        <Link className="item" to="/basket">
                            &nbsp;<i className="large shop icon"></i>&nbsp;
                        </Link>
                    </div>
                </div>;
    }

    componentDidMount(): void {
        BasketStore.addListener(this.onChange);
    }

    componentWillUnmount(): void {
        BasketStore.removeListener(this.onChange);
    }

    private onChange: () => void = () => {
        this.setState(this.getStateFromStores());
    };

    private getStateFromStores(): IMainNavigationState {
        return {
            products: BasketStore.getProducts().length
        };
    }
};