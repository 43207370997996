import * as React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as serviceWorker from './serviceWorker';


import App from "./App";
import CatalogPage from "./Components/CatalogPage/CatalogPage";
import ProductSelect from "./Components/CatalogPage/ProductSelect";
import CategorySelect from "./Components/CatalogPage/CategorySelect";
import BasketPage from "./Components/BasketPage/BasketPage";
import PartyPage from "./Components/PartyPage/PartyPage";

ReactDOM.render(
  <Router>
    <App>
      <Route exact path="/" component={CatalogPage}>
        <Route exact path="/" component={CategorySelect} />
        <Route path="/catalog/:category" component={ProductSelect} />
      </Route>
      <Route path="/basket" component={BasketPage} />
      <Route path="/party" component={PartyPage} />
    </App>
  </Router>
  ,
  document.getElementById("root"));
  serviceWorker.unregister();