import * as React from "react";

import MainNavigation from "./Components/Navigation/MainNavigation";

import AppActions from "./Actions/AppActions";

export default class App extends React.Component<{}, {}> {
    render(): React.ReactElement<{}> {
        return  <div className="ui container">
                    <div className="ui container">
                        <h2>Sarah's wereld</h2>
                    </div>
                    <MainNavigation />
                    <div className="ui container">
                        {this.props.children}
                    </div>
                </div>;
    }

    componentWillMount(): void {
        AppActions.loadApp();
    }
};