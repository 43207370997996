import * as React from "react";
import { Link } from "react-router-dom";
import { ICategory } from "../../Stores/Catalog";

interface ICategoryItemProps {
   category: ICategory;
}

export default class CategoryList extends React.Component<ICategoryItemProps, {}> {
    render(): React.ReactElement<{}> {
        return  <Link to={`/catalog/${this.props.category.id}`}>
                    <div className="ui segment">
                        <img className="ui fluid image" src={"images/categories/" + this.props.category.id + ".jpg"} />
                        <h3 className="center">{this.props.category.name}</h3>
                    </div>
                </Link>;
    }
}
