/// <reference path="../../../

import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { IProduct } from "../../Stores/Catalog";
import BasketStore from "../../Stores/BasketStore";

import ProductList from "../ProductList/ProductList";

interface IBasketPageState {
   products: Array<IProduct>;
}

export default class BasketPage extends React.Component<{}, IBasketPageState> {
    constructor(props: {}) {
        super(props);
        this.state = this.getStateFromStores();
    }

    render(): React.ReactElement<{}> {
        return  <div>
                    <h1>Boodschappenmandje</h1>
                    <ProductList products={this.state.products} />
                </div>;
    }

    componentDidMount(): void {
        BasketStore.addListener(this.onChange);
    }

    componentWillUnmount(): void {
        BasketStore.removeListener(this.onChange);
    }

    private onChange: () => void = () => {
        this.setState(this.getStateFromStores());
    };

    private getStateFromStores(): IBasketPageState {
        return {
            products: BasketStore.getProducts()
        };
    }
}
