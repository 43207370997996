

import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { IProduct } from "../../Stores/Catalog";
import BasketStore from "../../Stores/BasketStore";
import BasketActions from "../../Actions/BasketActions";



interface IProductListProps {
    products: Array<IProduct>;
}

export default class ProductList extends React.Component<IProductListProps, {}> {
    render(): React.ReactElement<{}> {
        return this.getCards(this.props.products);
    }

    getCards(products: Array<IProduct>): React.ReactElement<{}> {
        return  <div className="ui special cards">
                {products.map((product: IProduct) => {
                    return  <div key={product.id} className="card">
                                <div className="blurring dimmable image">
                                    <div className="ui dimmer">
                                        <div className="content">
                                            <div>
                                                <h5>Prijs</h5>
                                                <p><b>{product.points.price}</b></p>
                                            </div>
                                            <div>
                                                <h5>Cool</h5>
                                                <p><b>{product.points.fun}</b></p>
                                            </div>
                                            <div>
                                                <h5>Natuur</h5>
                                                <p><b>{product.points.nature}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <img className="image" src={"/images/products/" + product.id + ".jpg"} />
                                </div>
                                <div className="content">
                                    <a className="header">{product.name}</a>
                                    <div className="meta">
                                        <span className="cinema"><b>Materiaal: </b>{product.material}</span>
                                    </div>
                                    <p>{product.description}</p>
                                </div>
                                {this.getActionButton(product)}
                            </div>;
                })}
                </div>;
    }

    getActionButton(product: IProduct): React.ReactElement<{}> {
        var actionButton: React.ReactElement<{}> =  <div className="ui bottom attached button" onClick={() => this.addButtonClick(product)}>
                                                        <i className="add icon"></i>
                                                        Toevoegen
                                                    </div>;
        if (BasketStore.containsProduct(product)) {
            actionButton =  <div className="ui bottom attached button" onClick={() => this.removeButtonClick(product)}>
                                <i className="remove icon"></i>
                                Verwijderen
                            </div>;
        }
        return actionButton;
    }

    componentDidMount(): void {
        BasketStore.addListener(this.onChange);
        $(".special.cards .image").dimmer({
            on: "hover"
        });
    }

    componentWillUnmount(): void {
        BasketStore.removeListener(this.onChange);
    }

    addButtonClick(product: IProduct): void {
        BasketActions.addProduct(product);
    }

    removeButtonClick(product: IProduct): void {
        BasketActions.removeProduct(product);
    }

    private onChange: () => void = () => {
        this.forceUpdate();
    };
}
